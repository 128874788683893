import React from 'react'
import ReactDOM from 'react-dom/client'

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

import BuyTickets from './BuyTickets'
import Confirmation from './Confirmation'
import Voucher from './Voucher'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'

const firebaseConfig = {
  apiKey: 'AIzaSyCQUiVe35uASTvOYR-Syj4M-yPXF2GNQ28',
  authDomain: 'entradas-plan-b.firebaseapp.com',
  projectId: 'entradas-plan-b',
  storageBucket: 'entradas-plan-b.appspot.com',
  messagingSenderId: '506197463965',
  appId: '1:506197463965:web:57524a3371a5da5f8b9574'
}

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)

const router = createBrowserRouter([
  {
    path: '/',
    element: <BuyTickets db={db} />
  },
  {
    path: '/confirmacion',
    element: <Confirmation db={db} />
  },
  {
    path: '/comprobante',
    element: <Voucher db={db} />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
